.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #555555;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.5s linear infinite;
  display: block;

  &.big {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #555555;
    width: 45px;
    height: 45px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
