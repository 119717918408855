
.onboard {
  display: flex;
  flex-direction: row;

  .pf-copyright {
    text-align: right;
  }
}

.onboard-sidebar {
  background-color: $pf-color-grey-3;
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height:100vh;
  padding-bottom:37px;

  @media (max-width: $screenBreakpoint) {
    display: none;
  }
}

.onboard__back-btn {
  color: $pf-color-grey-8;
  border: 0;
  background-color: white;
  padding: 8px 8px 8px 0;
  cursor: pointer;
  outline: none;

  @media (min-width: $screenBreakpoint + 1px) {
    display: none;
  }
}

.onboard-sidebar__back-btn-icon {
  color: inherit;
  font-size: 20px;
  position: relative;
  top: 4px;
  left: -3px;
  font-family: Poppins, sans-serif;
}

.onboard__step-list {
  position: relative;
  z-index: 40;
  list-style-type: none;
}

.onboard__step-btn {
  cursor: pointer;
  color: $pf-color-grey-10;
  width: 100%;
  outline: none;
  border: 0;
  background-color: transparent;
  text-align: left;

  &:disabled  {
    color: $pf-color-grey-7;
  }

  &:hover {
    color: $pf-color-grey-9;
  }
  &:disabled {
    &:hover {
      color: $pf-color-grey-7;
    }
  }
}

.onboarding__step--new-account {
  display: block;

  @media (max-width: $screenBreakpoint) {
    display: none;
  }
}

.onboarding__step--active {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -8px;
    width: 10px;
    height: 2px;
    background-color: $pf-color-grey-10;
    top: 50%;
    margin-top: -2px;
  }
}

.onboard-content {
  background-color: white;
  height: calc(100vh - 37px);
  flex: 1;
  overflow: auto;
  flex-direction: column;
}

.onboard-content__inner {
  width: 100%;
  max-width: 500px;
  z-index: 100;
  background-color: white;
  margin: -80px auto 0;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  top: 50%;
  position: relative;

  &--long {
    margin-top: -40px;

    h5 {
      font-weight: bold;
    }

    p, li {
      color: $pf-color-grey-8;
    }
  }
}

.pf-form {
  &--onboard {
    max-width: 480px;
  }

  &--long {
    min-height: 200px;
    max-height: 400px;
    height: 40vh;
    max-width: 490px;
    overflow: auto;

    @media (max-height: $screenBreakpoint) {
      max-height: 200px;
    }
  }
}

.onboarding-form {
  width: 280px;
}

.onboard__legal {
  max-height: 200px;
  overflow: auto;
}


.password-requirements-list {
  padding-left: 16px;
}

.password-requirements-list-item {
  position: relative;

  &::before {
    content: ' ';
    z-index: 10;
    display: none;
    position: absolute;
    background-color: #586c86;
    top: 4px;
    left: -10px; 
  }

  &.password-requirements-list-item--valid {

    &::before {
      display: block;
    }
  }
}

.password-requirements-list-item-icon {
  position: absolute;
  left: -17px;
  top: 3px;
}