.gdpr__modal-body {
  padding: 0;
}

.gdpr__disclaimer {
  padding: 7px 14px;
  background: #f9fafc;
  font-size: 12px;
  text-align: justify;
  color: #373e4a;
  margin: 15px;
}

.gdpr__main-content {
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 0 15px;

  @media (min-width: $pf-sm-min) {
    max-height: 400px;
    overflow: scroll;
  }
  @media (max-width: $pf-sm-min) {
    max-height: 190px;
    overflow: scroll;
  }

  li {
    color: $pf-color-grey-8;
  }
}

.gdpr__checkbox-container {
  padding: 2px 14px;
  background: lighten(#008243, 73%);
  border-radius: 4px;
  color: #008243;
  border: 1px solid #07A855;
}

.gdpr__consent-loader {
  height: 40vh;
  @media (max-height: $screenBreakpoint) {
    height: 200px;
  }
  position: relative;
}

.gdpr__publisher-consent table,
.gdpr__researcher-consent table {
  display: block;
  overflow-x: scroll;
  max-width: 600px;
  border: 1px solid #ddd;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.gdpr__researcher-consent table {
  color: $pf-color-grey-8;
}

.gdpr__publisher-consent table tr:not(:last-child),
.gdpr__researcher-consent table tr:not(:last-child),
.gdpr__publisher-consent table thead tr,
.gdpr__researcher-consent table thead tr {
  border-bottom: 1px solid #ddd;
}

.gdpr__publisher-consent table th,
.gdpr__publisher-consent table td,
.gdpr__researcher-consent table th,
.gdpr__researcher-consent table td {
  padding: 4px 8px;
}

.gdpr__publisher-consent table th:not(:last-child),
.gdpr__publisher-consent table td:not(:last-child),
.gdpr__researcher-consent table th:not(:last-child),
.gdpr__researcher-consent table td:not(:last-child) {
  border-right: 1px solid #ddd;
}
