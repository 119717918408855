.sso-page {
  width: 100%;
  height: 100%;

  max-width: 416px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    display: block;
  }

  .sso-form {
    display: flex;

    form {
      flex: 1;
    }
  }

  label {
    display: block;
    font-size: 12px;
    font-weight: normal;
    color: #323945;
    margin-bottom: 4px;
    text-decoration: none;
  }

  input {
    box-sizing: border-box;
    border-radius: 4px;
    background: white;
    border: solid 1px #e0e6ed;
    padding: 12px;
    color: #5c6776;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    display: block;
  }

  button[type=submit] {
    display: block;
    margin-top: 12px;
    width: 100%;
    border: 0;
    border-radius: 30px;
    background-color: #0e68f9;
    color: white;
    font-weight: bold;
    padding: 16px 20px;
    height: 51px;
    line-height: 1.5;
    box-sizing: border-box;

    &:hover {
      box-shadow: 0 4px 6px rgba(143,152,169,0.11), 0 1px 3px rgba(143,152,169,0.08);
    }
  }
}