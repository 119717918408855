html, body, #root {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family;
}

$featuredPanelWidth: 50%;

input[type=radio], input[type=checkbox] {
  margin: 0;
}

.panel-wrapper {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-right: $featuredPanelWidth;

  @media (max-width: $pf-sm-max) {
    height: auto;
    padding-right: 0;
  }
}

a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.logo {
  text-align: center;
  padding: 16px;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 40px;
  }

  & a {
    text-decoration: none;
  }

  i {
    color: #e44044;
    display: block;
    margin-bottom: 7px;
    font-size: 38px;
  }
}

.slider-img-wrapper {
  width: 100%;
}

.slider-img {
  height: auto;
  margin: 0 auto;
  &--1 {
    width: 240px;
  }
  &--2 {
    width: 100%;
  }
}

.featured-panel {
  height: auto;
  min-height: 100vh;
  width: $featuredPanelWidth;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f9fafc;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: $pf-sm-max) {
    display: none;
  }

  &__frame {
    position: relative;
    width: 600px;
    border-radius: 10px;
    margin: 20px auto 0;
    @media (min-width: 1400px) {
      width: 700px;
    }
  }

  &__testimonials {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    color: $pf-color-grey-7;
    margin-top: -40px;

    &__title {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 24px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      i {
        color: rgba(255,255,255,0.72);
        font-size: 17px;
      }
    }

  }

  &__content {
    height: 100%;
    position: relative;

    &--offer {
      display: table;
      width: 100%;
      background-image: url(../images/offer.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include retinaImage('../images/offer@2x.jpg');

      &-heading {
        display: block;
        color: #fff;
        min-height: 200px;

        &__title {
          font-size: 52px;
          font-weight: bold;
          line-height: 1.06;
          margin: 0;
        }

        &__subtitle {
          margin: 0;
          font-size: 32px;
          font-weight: 300;
        }

        &__paragraph {
          font-size: 15px;
          margin: 24px 0 0 0;
          font-weight: normal;
        }
      }
    }
  }

  &__slide {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__content-wrapper {
      display: table;
      width: 100%;
      height: 100%;
    }

    &__heading {
      display: table-cell;
      vertical-align: middle;
      padding: 100px 40px;
      color: #fff;
      min-height: 200px;
    }

    &__title {
      font-size: 24px;
      font-weight: 400;
      margin: 0;
    }

    &__paragraph {
      min-height: 70px;
      margin: 16px 0 0 0;
      font-weight: 300;
    }

    &__quote {
      font-size: 24px;
      font-weight: 300;
    }

    &__publisher {
      padding: 4px 50px;
      background-size: 36px 36px !important;

      &__title {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
      }

      &__name {
        margin: 0;
        font-size: 12px;
        font-weight: normal;
      }

      &--grabpoints {
        background: url('../images/quotes/publisher-grabpoint.png') no-repeat center left;
      }

      &--lottery {
        background: url('../images/quotes/publisher-freepostcode.png') no-repeat center left;
      }

      &--alchemy {
        background: url('../images/quotes/publisher-alchemy.png') no-repeat center left;
      }

      &--fishfarm {
        background: url('../images/quotes/publisher-fishfarm2.png') no-repeat center left;
      }

    }

    &--researcher-1 {
      background-image: url('../images/slides/researcher-slide-1.jpg');
      @include retinaImage('../images/slides/researcher-slide-1@2x.jpg');
    }

    &--researcher-2 {
      background-image: url('../images/slides/researcher-slide-2.jpg');
      @include retinaImage('../images/slides/researcher-slide-2@2x.jpg');
    }

    &--researcher-3 {
      background-image: url('../images/slides/researcher-slide-3.jpg');
      @include retinaImage('../images/slides/researcher-slide-3@2x.jpg');
    }

    &--publisher {
      background-image: url('../images/slides/publisher-slide.jpg');
    }

  }
  &__slide-new {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .7);
      z-index: 10;
    }

    &__content-wrapper {
      display: table;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 100;
    }

    &__heading {
      display: table-cell;
      vertical-align: middle;
      color: $pf-color-grey-10;
      min-height: 200px;
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    &__paragraph {
      min-height: 70px;
      margin: 16px 0 0 0;
      font-weight: 500;
    }

    &__quote {
      font-size: 24px;
      font-weight: 300;
    }

    &__publisher {
      padding: 4px 50px;
      background-size: 36px 36px !important;

      &__title {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
      }

      &__name {
        margin: 0;
        font-size: 12px;
        font-weight: normal;
      }

    }

    &--new-1 {
      background-image: url('../images/slides/Researchers.jpg');
    }
    &--new-2 {
      background-image: url('../images/slides/Publishers.jpg');
    }

  }

  &-trusted-by {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    &__item {
      position: relative;
      background-image: url('../images/slides/logos-register.png');
      background-size: cover;
      margin: 0 12px;
    }

    &__item--netflix {
      width: 70px;
      height: 34px;
      background-position: 0 0;
      top: 3px;
    }
    &__item--microsoft {
      width: 123px;
      height: 34px;
      background-position: -70px 0;
    }
    &__item--bbdo {
      width: 60px;
      height: 35px;
      background-position: -202px 0;
      top: 5px;
    }
    &__item--adidas {
      width: 47px;
      height: 34px;
      background-position: 47px 0;
      top: -4px;
    }
  }
}

.panel-header {
  display: flex;
  width: 100%;
  flex: 1;

  .logo,
  .switch-action {
    padding: 40px;
    @media (max-width: $pf-sm-max) {
      padding: 40px 24px;
    }
  }
  .switch-action {
    margin-left: auto;
  }
}

.main-panel {
  height: auto;
  overflow: auto;
  position: relative;
  flex-grow: 1;

  @media (max-width: $pf-sm-max) {
    margin-left: 0;
  }

  &__table {
    display: table;
    width: 100%;
    height: 100%;
  }

  &__table-cell {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__content {
    width: auto;
    max-width: 426px;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
    margin-top: 0;

    &--login {
      margin-top: 18px;
    }
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    color: $pf-color-grey-10;
    margin: 0 0 14px;
  }

  &__subtitle {
    font-size: 11px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 16px;
    color: $pf-color-grey-10;
  }
}

.slick-slider {
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide {
        height: 100%;
      }
    }
  }

  .slick-dots {
    top: 65px;
    z-index: 2;

    li {
      width: auto;
      height: auto;
      background-color: rgb(191, 200, 213);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      overflow: hidden;

      button {
        padding: 0;
        background: black;
        width: 16px;
        height: 4px;

        background: rgba(255,255,255,0.4);

        &:hover {
          background: #eceff4;
          background: rgba(255,255,255,0.6);
        }
      }

      &.slick-active button {
        background: $pf-color-grey-10;

        &:hover {
          background: #0e68f9;
        }
      }

      button:before {
        content: '';
        width: auto;
        height: auto;
      }
    }
  }
}

.pf-btn {
  padding: 12px 32px;
  text-decoration: none;
  color: $pf-color-grey-10;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2em;
  border-radius: 30px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  &__icon {
    vertical-align: text-top;
    margin-right: 12px;
  }

  &--bordered {
    border: 1px solid #c6ccd7;
  }

  &--inline {
    display: inline-block;
    vertical-align: middle;
  }

  &--guest,
  &--google {
    text-align: center;
    color: #323945;
    font-weight: normal;
    line-height: 1.33;
    cursor: pointer;
    padding: 12px 24px;
    border: solid 1px #e0e6ed;
  }

  &--google {
    width: 345px;
    // properties below to support guest login
    // width: 185px;
    // margin-right: 4px;
    // padding: 12px 18px;

    .pf-btn__icon {
      margin-right: 8px;
    }
  }

  &--guest {
    width: 185px;
    margin-left: 4px;
  }

  &--sso {
    color: #0e68f9;
    font-weight: normal;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    background-color: transparent;

    &:hover {
      color: #024bd4;
    }
  }
}

.switch-action {
  &__btn {
    min-width: 60px;
    text-align: center;
    text-transform: capitalize;

    &:active, &:focus, &:hover {
      box-shadow: 0 4px 6px rgba(143,152,169,0.3),0 1px 3px rgba(143,152,169,0.3);
      color: $pf-color-grey-10;
      border-color: transparent;
    }
  }

  &__label {
    display: inline-block;
    font-size: 12px;
    font-weight: 300;
    color: #495561;
    margin-right: 8px;
    vertical-align: middle;

    @media (max-width: $pf-xs-max) {
      display: none;
    }
  }
}

.switch-user {
  max-width: 280px;
  width: 100%;

  &__btn {
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 0;
    text-align: center;
    font-size: 13px;
    color: $pf-color-grey-8;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 2px solid rgba(0,0,0,0);
    @include btnTransition();

    &:active, &:focus, &:hover {
      color: $pf-color-blue;
    }

    &:hover {
      color: $pf-color-blue;
    }

    &--active {
      border-bottom: 2px solid $pf-color-blue;
      color: $pf-color-grey-10;

      &:hover {
        border-bottom: 2px solid $pf-color-blue;
      }

      &-researcher {
        border-color: #fb3b71;

        &:hover {
          border-color: #fb3b71;
        }
      }
    }
  }
}

.pf-select > div {
  border-color: $pf-color-grey-6;

  &:hover {
    border-color: $pf-color-grey-10;
  }
}

.pf-clearer {
  clear: both;
}

.pf-form {



  &__message {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.67;
    margin-top: 16px;

    &--error {
      color: #ff626a;
    }

    &--padding {
      padding-bottom: 12px;
      font-size: 14px;
    }
  }

  &__checkbox {
    cursor: pointer;

    i {
      vertical-align: middle;
      margin-right: 8px;
      font-size: 24px;

      &[class*="-checked"] {
        color: #4fb8ff;
      }

      &[class*="-unchecked"] {
        color: #8c8f93;
      }

    }
  }

  &__textfield {
    margin-bottom: 16px;

    &__label {
      display: block;
      font-size: 10px;
      font-weight: normal;
      color: $pf-color-grey-10;
      margin-bottom: 4px;
      text-decoration: none;

      &--subtle {
        color: #8f98a9;
        text-align: center;
      }

      &--inline {
        display: inline-block;
      }

      &--pointer {
        cursor: pointer;
      }

      &--pull-left {
        float: left;
      }

      &--pull-right {
        float: right;
      }

      a {
        color: #0E68F9;
        text-decoration: none;
      }
    }

    &__input {
      box-sizing: border-box;
      border-radius: 4px;
      background: white;
      border: solid 1px rgb(224, 230, 237);
      padding: 12px;
      color: #5c6776;
      font-size: 14px;
      font-weight: normal;
      width: 100%;
      display: block;
    }

    &__input::placeholder {
      font-size: 14px;
      color: $pf-color-grey-7;
    }

    &-button {
      position: relative;
      border-radius: 30px;
      background: rgba(255,255,255,1);
      border: solid 1px #c6c7c9;
      padding: 16px 20px;
      color: #5c6776;
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 500 !important;
      line-height: 1.33;
      height: 51px;
      box-sizing: border-box;
      transition: 300ms;

      &--submit {
        border: 0;
        background-color: #0e68f9;
        box-shadow: 0 4px 6px rgba(143,152,169,0.11), 0 1px 3px rgba(143,152,169,0.08);
        color: #fff;
        font-weight: bold;
        display: block;

        &:active, &:hover, &:focus {
          color: #fff;
        }

        &:hover {
          box-shadow: 0 4px 6px rgba(143,152,169,0.3),0 1px 3px rgba(143,152,169,0.3);
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      &--cancel {
        background-color: white;
        color: $pf-color-grey-7;
        border: 0;
        font-size: 14px;
      }

    }

    &__or {
      text-transform: uppercase;
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.1px;
      color: #8f98a9;
    }
  }
}

.pf-copyright {
  text-align: center;
  color: #8f98a9;
  font-size: 12px;
  white-space: nowrap;
  align-self: flex-end;
  margin-bottom: 10px;
  width: 100%;
}

.pf-select > div {
  padding: 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.css-bgvzuu-indicatorSeparator {
  display: none;
}

.decorative-triangle {
  position: absolute;
  @media (max-width: $screenBreakpoint) {
    display: none;
  }

  &--1 {
    bottom: 0;
    right: 0;
    @include decorativeRectangle($width: 210px, $height: 210px, $bgColor: $pf-color-default, $rotate: -128, $posX: 140px, $posY: 80px);
  }
  &--2 {
    top: -40px;
    left: 0;
    @include decorativeRectangle($width: 180px, $height: 160px, $bgColor: $pf-color-yellow, $rotate: -46, $posX: -20px, $posY: -24px);
  }
  &--3 {
    bottom: 130px;
    left: -30px;
    @include decorativeRectangle($width: 180px, $height: 180px, $bgColor: white, $rotate: -46, $posX: -20px, $posY: 21px);
  }

  &--4 {
    bottom: 0;
    left: 0;
    @include decorativeRectangle($width: 180px, $height: 160px, $bgColor: $pf-color-yellow, $rotate: -46, $posX: -37px, $posY: 130px);
  }
}

.font-bold {
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}

.radio-button-wrapper {
  border: 1px solid $pf-color-grey-6;
  padding: 15px;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-button__label {
  margin-left: 8px;
  display: block;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.checkbox-wrapper {
  font-weight: normal;
  cursor: pointer;
  background-color: $pf-color-green-3;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox__label {
  margin-left: 6px;
}
.checkbox__input {
  position: absolute;
  left: -9999px;
}

.checkbox__active-state {
  font-size: 15px;
  color: $pf-color-blue;

  &--unchecked {
    color: $pf-color-grey-10;
  }
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.viewport-height {
  height: 100vh;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.color-green {
  color: $pf-color-green;
}

//Increase font-size for input fields in case the mobile will try to zoom to help readability. (iOS)
//https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling#Browser_compatibility
@supports (-webkit-overflow-scrolling: touch) {
  .radio-button-wrapper, .pf-form__textfield__input, .pf-select, .checkbox-wrapper {
    font-size: 16px;
  }
}

.margin-none {
  margin: 0;
}