@import "_spacing";

//colors
$pf-color-default: #fb3b71;
$pf-color-grey-3: #f9fafc;
$pf-color-grey-6: #e0e6ed;
$pf-color-grey-7: #bfc8d5;
$pf-color-grey-8: #8f98a9;
$pf-color-grey-9: #5c6776;
$pf-color-grey-10: #323945;
$pf-color-yellow: #ffe401;
$pf-color-green: #13ce66;
$pf-color-green-2: #f4fcf8;
$pf-color-green-3: #baffd0;
$pf-color-blue: #0e68f9;

$icomoon-font-path: "fonts" !default;

$icon-unchecked: "\e906";
$icon-checked: "\e907";
$icon-microsoft: "\e900";
$icon-millward: "\e901";
$icon-oracle: "\e902";
$icon-tmobile: "\e903";
$icon-Pollfish_logo: "\e904";
$icon-Pollfish: "\e905";

$font-base: -apple-system,
BlinkMacSystemFont,
“Segoe UI”,
Roboto,
Oxygen-Sans,
Ubuntu,
Cantarell,
“Helvetica Neue”,
sans-serif;
$font-family: Poppins, $font-base;

* {
  text-rendering: optimizeSpeed;
}

.pf-link {
  color: $pf-color-blue !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #c6c7c9;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #c6c7c9;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #c6c7c9;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #c6c7c9;
}

// Breakpoints
// ================

$pf-sm-min: 600px !default;
$screenMobileHeight: 640px;
$screenBreakpoint: 700px;
$pf-md-min: 960px !default;
$pf-xs-max: ($pf-sm-min - 1) !default;
$pf-sm-max: ($pf-md-min - 1) !default;

