@mixin btnTransition() {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

@mixin retinaImage($val) {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
      background-image: url($val);
    }
}

@mixin decorativeRectangle($width, $height, $bgColor: $pf-color-default, $rotate: -128, $posX: 0, $posY: 0) {
  overflow: hidden;
  width: $width + 40px;
  height: $height + 40px;

  &:after {
    content: "";
    position: absolute;
    left: $posX;
    top: $posY;
    width: $width;
    height: $height;
    -webkit-transform: rotate(#{$rotate}deg);
    -moz-transform: rotate(#{$rotate}deg);
    -ms-transform: rotate(#{$rotate}deg);
    -o-transform: rotate(#{$rotate}deg);
    transform: rotate(#{$rotate}deg);
    border-radius: 40px;
    background-color: $bgColor;
  }

}
