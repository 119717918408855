$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-eye: "\e900";
$icon-eye-off: "\e901";
$icon-unchecked: "\e906";
$icon-checked: "\e907";
$icon-Pollfish_logo: "\e904";
$icon-Pollfish: "\e905";
$icon-pollfish_logo_full: "\e902";

