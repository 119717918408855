.autocomplete {
  border : solid 1px #c6c7c9;
}

.autocomplete_container {
  list-style-type: none;
  padding: 5px 0;
}

.autocomplete_item {
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: none repeat scroll 0 0 #ebebeb;
  }

  img {
    height: 30px;
    width: 30px;
  }
}
